@import '../../index.scss';

.container {
  display: flex;
  flex-direction: row;
  gap: 2px;
}
.singleIndicator {
  width: 6px;
  height: 16px;
  border-radius: 3px;
}

.pink {
  background-color: $pink;
}

.grey {
  background-color: $brownGrey;
}
