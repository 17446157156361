@import '../../index.scss';

.container {
  width: 100%;
  background-color: white;
  border-radius: 1px;
  padding: 16px;
}

.title {
  @include NormalText;
  word-wrap: break-word;
  margin-bottom: 8px;
}

.content {
  @include SmallSemiBoldText;
  margin-bottom: 16px;
}

.highlightedContent {
  @include SmallSemiBoldText;
  border-radius: 2px;
  background-color: $selected-blue;
  color: white;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 2px;
}

.tree {
  padding-top: 4px;
  padding-bottom: 8px;
  @include SmallText;
  color: $grey;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
