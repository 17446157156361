@import-normalize "opinionated.css";

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-weight: normal;
}

body {
  margin: 0;
  font-family: 'Graphik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root,
.App {
  height: 100vh;
  width: 100vw;
  background-color: #5bc5f2;
}

.content-holder {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 6rem;
  height: '100%';
}

button {
  background-color: transparent;
  border: none;

  &:active {
    opacity: 0.7;

    &[disabled] {
      opacity: 1;
    }
  }
}

[type='text'],
[type='search'] {
  -webkit-appearance: none;
}

$fontPath: 'Assets/';

@font-face {
  font-family: FIATADVERT01A;
  src: local(FIATADVERT01A), url($fontPath + 'FIATADVERT01.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Montserrat-Regular;
  src: local(Montserrat), url($fontPath + 'Montserrat-Regular.ttf');
}

@font-face {
  font-family: Montserrat-Medium;
  src: local(Montserrat), url($fontPath + 'Montserrat-Medium.ttf');
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: local(Montserrat), url($fontPath + 'Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: Montserrat-Bold;
  src: local(Montserrat), url($fontPath + 'Montserrat-Bold.ttf');
}

@mixin Title {
  font-family: FIATADVERT01A;
  font-size: 48px;
  text-align: left;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: white;
}

@mixin SmallTitle {
  font-family: FIATADVERT01A;
  font-size: 24px;
  text-align: left;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: white;
}

@mixin Subtitle {
  font-family: Montserrat-SemiBold;
  font-size: 16.6px;
  text-align: left;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: $medium-blue;
  text-transform: uppercase;
}

@mixin LightText {
  font-family: Montserrat-Regular;
  font-size: 16px;
}

@mixin NormalText {
  font-family: Montserrat-SemiBold;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}

@mixin SmallText {
  font-family: Montserrat-Regular;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
}

@mixin SmallSemiBoldText {
  font-family: Montserrat-SemiBold;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
}

@mixin VerySmallText {
  font-family: Montserrat-Regular;
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
}

// Contants
$light-blue: #5bc5f2;
$medium-blue: #007cbf;
$selected-blue: #0f89cb;
$grey: #a9a9a9;
$brownGrey: #979797;
$pink: #e6007e;
$magenta: #ba4693;
$yellow: #ffe200;
