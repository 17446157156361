@import '../../index.scss';

.container {
  background-color: $light-blue;
  height: 100%;
  padding-top: 38px;
  padding-left: 20px;
  padding-right: 20px;
}

.title {
  @include Title;
}

.textInputLeftIcon {
  height: 16px;
  width: 16px;
  margin-left: 8px;
}

.searchField {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: white;
  height: 40px;
  border-radius: 4px;
  margin-bottom: 35px;
}

.textInput {
  width: 100%;
  border: none;
  border-color: transparent;
  @include NormalText;
  margin-left: 8px;
}

.textInput::placeholder {
  @include LightText;
  color: $grey;
}

.inputCross {
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.results {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loadingTitle {
  @include SmallTitle;
  text-align: center;
}

.offlineTitle {
  @include SmallTitle;
  color: white;
  text-align: center;
  padding-bottom: 1rem;
}

.offlineText {
  @include SmallSemiBoldText;
  color: white;
  text-align: center;
}
