@import '../../index.scss';

.container {
  @include SmallSemiBoldText;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.icons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;
}

svg:hover {
  rect {
    fill: #2591e2;
  }
}
