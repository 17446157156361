@import '../../../index.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 100%;
  text-align: center;

  cursor: pointer;
}

.text {
  @include VerySmallText;
  color: $brownGrey;
}

.selected {
  color: $selected-blue;
}
