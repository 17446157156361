@import '../../index.scss';

.container {
  background-color: $light-blue;
  height: 100%;
  padding-top: 38px;
  padding-left: 20px;
  padding-right: 20px;
}

.title {
  @include Title;
  margin-bottom: 30px;
  cursor: pointer;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 9px;
  margin-top: 16px;
}
