@import '../../index.scss';

.container {
  width: 100%;
  height: 80px;
  border-radius: 2px;
  background-color: #fff;

  display: flex;
  flex-direction: row;
  overflow: hidden;

  cursor: pointer;
}

.leftBorder {
  height: 100%;
  width: 4px;
  background-color: $yellow;
}

.text {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.category {
  @include SmallSemiBoldText;
}

.title {
  @include LightText;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
