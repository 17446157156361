@import '../../index.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.choice {
  @include SmallSemiBoldText;
  height: 16px;
  border-radius: 2px;
  padding-left: 8px;
  padding-right: 8px;
  height: 20px;
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
}

.separator {
  border-left: 1px solid white;
}

.separator:nth-child(1) {
  border-left: none;
}

.selected {
  background-color: $magenta;
  color: white;
}
