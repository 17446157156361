@import '../../index.scss';

.container {
  display: inline-flex;
  flex-direction: row;
}

.text {
  @include Subtitle;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding-left: 11px;
  padding-right: 5px;
  height: 20px;
}

.arrowLeft {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  background-color: white;
  border-right: 5px solid $light-blue;
}
