@import '../../index.scss';

.container {
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

.loadingText {
  @include SmallText;
  color: white;
  text-align: center;
  width: 100%;
}

.flippingCard {
  will-change: transform, opacity;
  background-size: cover;
}

.loadingSubtitle {
  padding-top: 25px;
  padding-bottom: 25px;
  @include SmallSemiBoldText;
  color: white;
  text-align: center;
}

.logos {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoGrid {
  display: grid;
  grid-template-columns: minmax(4rem, 10rem) minmax(4rem, 10rem) minmax(4rem, 10rem);
  column-gap: 2rem;
  row-gap: 2rem;
  justify-items: center;
  align-items: center;
}
