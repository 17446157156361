@import '../../index.scss';

.container {
  background-color: $light-blue;
  height: 100%;
  padding-top: 38px;
  padding-left: 20px;
  padding-right: 20px;
}

.title {
  @include Title;
  margin-bottom: 30px;
  cursor: pointer;
}

.categoryContainer {
  margin-bottom: 30px;
}

.subCategories {
  margin-top: 13px;
}

.noSubcategory {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.seeAll {
  @include NormalText;
}
