$go-back-button-height: 35px;

.container {
  width: 100vw;
  height: calc(100vh - #{$go-back-button-height});
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-x: hidden;
  top: 0;
  left: 0;
  background-color: black;
}

.loadingAnimation {
  display: flex;
  flex: 1;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.goBackButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;

  width: 20;
  height: $go-back-button-height;
  z-index: 10;
}
